import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { format } from 'date-fns';
import {
  TableBody,
  TableFooter,
  TableFrame,
  TableHeader,
} from 'components/Table';
import Avatar from 'components/Avatar';
import TicketStatusDropDown from 'pages/admin-dashboard/TicketStatusDropdown';
import localDateTime from 'utils/localTime';

const tableColumns = {
  id: 'Id',
  event: 'Event',
  seatDetails: 'Seat Details',
  ticketDate: 'Event Date',
  ticketStatus: 'Ticket Status',
};

function makeTicketRow({
  id,
  eventName,
  lotteryLogo,
  section,
  row,
  seat,
  isConfirmed,
  isDispersed,
  claimed,
  dateTime,
}) {
  const sectionDetail = section && `Section ${section}`;
  const rowDetail = row && `Row ${row}`;
  const seatDetail = seat && `Seat ${seat}`;
  // Construct a string with only valid details
  const seatDetails = [sectionDetail, rowDetail, seatDetail]
    .filter((s) => s)
    .join(', ');

  const unclaimed = !isConfirmed && !isDispersed;
  const localTicketTime = localDateTime(dateTime);

  return {
    id,
    event: () => (
      <div className="d-flex align-items-center">
        <Avatar
          imgSrc={lotteryLogo}
          altText={eventName}
          className="me-3"
          size="md"
        />
        <div>
          <span className="fw-medium">{eventName}</span>{' '}
        </div>
      </div>
    ),
    seatDetails: () => seatDetails,
    ticketDate: (
      <div className="d-flex align-items-center">
        <div>{format(localTicketTime, 'EEEE, MMMM d, yyyy')}</div>
      </div>
    ),
    ticketStatus: (
      <TicketStatusDropDown
        isConfirmed={isConfirmed}
        isDispersed={isDispersed}
        isClaimed={claimed}
        lotteryDetail
        unclaimed={unclaimed}
        ticketId={id}
      />
    ),
  };
}

function SeasonLotteryDetailTickets() {
  const {
    lotteryTickets,
    lotteryTicketsCurrentPage,
    setLotteryTicketsCurrentPage,
    lotteryTicketsTotal,
    lotteryTicketsPageTotal,
    isLoading,
  } = useOutletContext();

  // Handle tickets from parent context
  let tickets = [];
  if (lotteryTickets?.length) {
    tickets = lotteryTickets.map((ticket) => makeTicketRow(ticket));
  }

  return (
    <TableFrame>
      <TableHeader
        title="Tickets"
        totalItems={lotteryTicketsTotal}
        description="All tickets in your lottery."
      />
      <TableBody
        columns={tableColumns}
        rows={tickets}
        isLoading={isLoading}
        noRowsMessage={
          isLoading ? 'Loading Lottery Tickets...' : 'No Lottery Tickets Found'
        }
      />
      <TableFooter
        currentPage={lotteryTicketsCurrentPage}
        pageTotal={lotteryTicketsPageTotal}
        setCurrentPage={setLotteryTicketsCurrentPage}
      />
    </TableFrame>
  );
}

export default SeasonLotteryDetailTickets;
