import { useCallback, useContext } from 'react';
import AuthenticationContext from '../AuthenticationContext'; // Ensure this points to where your context is defined

const useAuth = () => {
  const { msalInstance } = useContext(AuthenticationContext);

  const acquireToken = useCallback(
    async (scopes) => {
      try {
        const account = msalInstance.getAllAccounts()[0];
        const response = await msalInstance.acquireTokenSilent({
          scopes,
          account,
        });
        return response.accessToken;
      } catch (error) {
        if (error.name === 'InteractionRequiredAuthError') {
          return msalInstance.acquireTokenRedirect({ scopes });
        }
        throw error;
      }
    },
    [msalInstance],
  );

  return { acquireToken };
};

export default useAuth;
