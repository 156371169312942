import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ActionButtons from 'components/ActionButtons';
import Avatar from 'components/Avatar';
import Calendar from 'assets/icons/calendar-tiny-v2.svg';
import Clock from 'assets/icons/clock-tiny.svg';
import DragAndDrop from 'components/DragAndDrop';
import HeroImage from 'components/HeroImage/HeroImage';
import FieldRow, { FieldRowLeft, FieldRowRight } from 'components/FieldRow';
import cn from 'utils/classNames';
import {
  LotteryDetailsFields,
  UNCLAIMED_TICKET_OPTIONS,
  EVENT_TYPE_OPTIONS,
  DETAILS_STEP,
  DETAILS_FORM_TYPE,
} from 'constants/addLottery';

import Check from 'assets/icons/check-small.svg';

import styles from './LotterySteps.module.scss';

const {
  LOTTERY_NAME_FIELD,
  DESCRIPTION_FIELD,
  START_DATE_FIELD,
  START_TIME_FIELD,
  END_DATE_FIELD,
  END_TIME_FIELD,
  UNCLAIMED_TICKETS_FIELD,
  EVENT_TYPE_FIELD,
  VENUE_NAME_FIELD,
  VENUE_LINK_FIELD,
  EVENT_LOGO_FIELD,
  EVENT_COVER_PHOTO_FIELD,
} = LotteryDetailsFields;

const MAX_DESCRIPTION_LENGTH = 90;

const validationSchema = Yup.object().shape({
  [LOTTERY_NAME_FIELD]: Yup.string()
    .required('Please provide a lottery name.')
    .max(32, 'Please enter no more than 32 characters.'),
  [DESCRIPTION_FIELD]: Yup.string()
    .required('Please provide a lottery description.')
    .max(MAX_DESCRIPTION_LENGTH, 'Please enter no more than 90 characters.'),
  [START_DATE_FIELD]: Yup.string().required(
    'Please provide a lottery start date.',
  ),
  [START_TIME_FIELD]: Yup.string()
    .required('Please provide a lottery start time.')
    .when(START_DATE_FIELD, (startDate, schema) =>
      schema.test({
        test: (startTime) => {
          if (!startDate || !startTime) {
            return true;
          }
          const selectedDate = new Date(`${startDate} ${startTime}`);
          const currentDate = new Date();
          return currentDate <= selectedDate;
        },
        message: 'The start date cannot be in the past.',
      }),
    )
    .when([START_DATE_FIELD, END_DATE_FIELD], ([startDate, endDate], schema) =>
      schema.test({
        test: (startTime) => {
          if (!startDate || !endDate) {
            return true;
          }
          const selectedDate = new Date(`${startDate}`);
          const compareDate = new Date(`${endDate}`);

          // Compare date parts
          const isSameDate =
            selectedDate.toDateString() === compareDate.toDateString();

          if (isSameDate) {
            const SIXTY_MINUTES = 60;
            const [hours, minutes] = startTime.split(':').map(Number);
            const totalMinutes = hours * SIXTY_MINUTES + minutes;

            const MIN_HOUR = 7;
            const MAX_HOUR = 17;
            const START_MINUTES = MIN_HOUR * SIXTY_MINUTES;
            const END_MINUTES = MAX_HOUR * SIXTY_MINUTES;

            // Check if the time falls outside allowed range on the same day.
            return totalMinutes >= START_MINUTES && totalMinutes < END_MINUTES;
          }

          return true; // If it's not the same date, return true.
        },
        message:
          'The start time must be between 7AM and 5PM if the lottery only runs for one day.',
      }),
    ),
  [END_DATE_FIELD]: Yup.string().required('Please provide a lottery end date.'),
  [END_TIME_FIELD]: Yup.string()
    .required('Please provide a lottery end time.')
    .when(
      [START_DATE_FIELD, START_TIME_FIELD, END_DATE_FIELD],
      ([startDate, startTime, endDate], schema) =>
        schema.test({
          test: (endTime) => {
            if (!startDate || !startTime || !endDate) {
              return true;
            }
            const startDateTime = new Date(`${startDate} ${startTime}`);
            const endDateTime = new Date(`${endDate} ${endTime}`);
            return endDateTime >= startDateTime;
          },
          message: 'The end date must come after the start date.',
        }),
    )
    .when([END_DATE_FIELD, START_DATE_FIELD], ([endDate, startDate], schema) =>
      schema.test({
        test: (endTime) => {
          if (!endDate || !startDate) {
            return true;
          }
          const selectedDate = new Date(`${endDate}`);
          const compareDate = new Date(`${startDate}`);
          // Compare date parts
          const isSameDate =
            selectedDate.toDateString() === compareDate.toDateString();

          if (isSameDate) {
            const SIXTY_MINUTES = 60;
            const [hours, minutes] = endTime.split(':').map(Number);
            const totalMinutes = hours * SIXTY_MINUTES + minutes;

            const MIN_HOUR = 7;
            const MAX_HOUR = 17;
            const START_MINUTES = MIN_HOUR * SIXTY_MINUTES;
            const END_MINUTES = MAX_HOUR * SIXTY_MINUTES;

            // Check if the time falls outside allowed range on the same day.
            return totalMinutes >= START_MINUTES && totalMinutes < END_MINUTES;
          }
          return true; // If it's not the same date, return true.
        },
        message:
          'The start time must be between 7AM and 5PM if the lottery only runs for one day.',
      }),
    )
    .when(END_DATE_FIELD, (endDate, schema) =>
      schema.test({
        test: (endTime) => {
          if (!endDate || !endTime) {
            return true;
          }
          const selectedDate = new Date(`${endDate} ${endTime}`);
          const currentDate = new Date();
          return currentDate <= selectedDate;
        },
        message: 'The end date cannot be in the past.',
      }),
    ),
  [UNCLAIMED_TICKETS_FIELD]: Yup.string().required(
    'Please select an option to handle unclaimed tickets.',
  ),
  [EVENT_TYPE_FIELD]: Yup.string().required('Please provide an event type.'),
  [VENUE_NAME_FIELD]: Yup.string().required(
    'Please provide the name of the venue.',
  ),
  [VENUE_LINK_FIELD]: Yup.string()
    .url('Please enter a valid website address, ex: "https://your-venue.com".')
    .required('Please provide the website of the venue.'),
  [EVENT_LOGO_FIELD]: Yup.string(),
  [EVENT_COVER_PHOTO_FIELD]: Yup.string(),
});

/**
 * Lottery Detail Step, handles the steps state and when submitted invokes the handleLotteryDetails function.
 * @param {Object} props
 * @param {Function} props.handleLotteryDetails formik onSubmit handler
 * @returns {JSX.Element}
 */
function LotteryDetails({
  handleLotteryDetails,
  handleCancel,
  actionLabel,
  unpublishedLottery,
}) {
  const initialValues = {
    [LOTTERY_NAME_FIELD]: '',
    [DESCRIPTION_FIELD]: '',
    [START_DATE_FIELD]: '',
    [START_TIME_FIELD]: '',
    [END_DATE_FIELD]: '',
    [END_TIME_FIELD]: '',
    [UNCLAIMED_TICKETS_FIELD]: UNCLAIMED_TICKET_OPTIONS[1].value,
    [EVENT_TYPE_FIELD]: '',
    [VENUE_NAME_FIELD]: '',
    [VENUE_LINK_FIELD]: '',
    [EVENT_LOGO_FIELD]: '',
    [EVENT_COVER_PHOTO_FIELD]: '',
    ...(!unpublishedLottery[DETAILS_STEP] || unpublishedLottery[DETAILS_STEP]),
  };
  return (
    <div className="w-100 d-flex flex-column align-items-start gap-6">
      <Formik
        initialValues={initialValues}
        onSubmit={handleLotteryDetails}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik) => {
          function isInvalid(field) {
            return !!(formik.touched[field] && formik.errors[field]);
          }

          function setImageFormField(event, fieldName) {
            // Because of the way the useFetchWithMsal hook works, we cannot send the file as a file upload, so we are sending it as a base64 instead, will have to look if there is a better way to do this
            const reader = new FileReader();
            reader.readAsDataURL(event[0]);
            reader.onloadend = () => {
              formik.setValues({
                ...formik.values,
                [`${fieldName}`]: reader.result,
              });
            };
          }

          return (
            <>
              <section className="d-flex flex-column align-items-start gap-5 w-100">
                <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
                  <div className="d-flex flex-column align-items-start gap-1">
                    <h2 className="m-0 fs-2 fw-semibold lh-lg">
                      Step 1: Add Lottery Details
                    </h2>
                    <p className="m-0 fs-4 text-secondary">
                      Add your season lottery photo and details here.
                    </p>
                  </div>

                  <ActionButtons
                    actionLabel={actionLabel}
                    handleAction={() => formik.handleSubmit()}
                    handleCancel={handleCancel}
                  />
                </div>

                <div className="hr-divider" />
              </section>
              <Form
                id="LotteryDetails"
                className="w-100"
                data-form_type={DETAILS_FORM_TYPE}
              >
                <div className="w-100 d-flex flex-column gap-5">
                  <FieldRow fieldName={LOTTERY_NAME_FIELD}>
                    <FieldRowLeft>
                      <label
                        htmlFor={LOTTERY_NAME_FIELD}
                        className="fs-4 text-secondary-emphasis fw-semibold m-0"
                      >
                        Lottery Name*
                      </label>
                      <p className="fs-4 text-secondary m-0">
                        This will be displayed on the lottery page.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight>
                      <Field
                        id={LOTTERY_NAME_FIELD}
                        name={LOTTERY_NAME_FIELD}
                        required
                        className="form-control w-100"
                        placeholder="Provide a name for your lottery"
                        aria-invalid={isInvalid(LOTTERY_NAME_FIELD)}
                        aria-describedby="name-errors"
                      />
                      {isInvalid(LOTTERY_NAME_FIELD) && (
                        <div
                          id={`${LOTTERY_NAME_FIELD}-errors`}
                          className="text-danger mt-1 fs-4"
                        >
                          {formik.errors[LOTTERY_NAME_FIELD]}
                        </div>
                      )}
                    </FieldRowRight>
                  </FieldRow>

                  <FieldRow fieldName={DESCRIPTION_FIELD}>
                    <FieldRowLeft>
                      <label
                        htmlFor={DESCRIPTION_FIELD}
                        className="fs-4 text-secondary-emphasis fw-semibold m-0"
                      >
                        Lottery Description*
                      </label>
                      <p className="fs-4 text-secondary m-0">
                        A quick snapshot of the lottery.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight>
                      <Field
                        as="textarea"
                        rows="4"
                        id={DESCRIPTION_FIELD}
                        name={DESCRIPTION_FIELD}
                        required
                        className="form-control"
                        placeholder="Share your thoughts and excitement about the upcoming season ticket lottery here"
                        aria-invalid={isInvalid(DESCRIPTION_FIELD)}
                        aria-describedby="name-errors"
                      />
                      {isInvalid(DESCRIPTION_FIELD) ? (
                        <div
                          id={`${DESCRIPTION_FIELD}-errors`}
                          className="text-danger mt-1 fs-4"
                        >
                          {formik.errors[DESCRIPTION_FIELD]}
                        </div>
                      ) : (
                        <div className="text-gray form-text mt-1 fs-4">
                          {MAX_DESCRIPTION_LENGTH -
                            formik.values[DESCRIPTION_FIELD].length}{' '}
                          characters left.
                        </div>
                      )}
                    </FieldRowRight>
                  </FieldRow>

                  <FieldRow fieldName="run-time">
                    <FieldRowLeft>
                      <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
                        Lottery Run Time*
                      </p>
                      <p className="fs-4 text-secondary m-0">
                        We highly recommend running a lottery for at least 24
                        hours.
                      </p>
                      <p className="fs-4 text-secondary m-0 my-1">
                        Please note that notifications for lotteries starting
                        will only be sent during the working hours of 7AM-5PM in
                        the time zone you are in.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight>
                      <div className="d-flex flex-column gap-4 gap-lg-8">
                        <div className="d-flex flex-column flex-lg-row gap-4 flex-fill w-100">
                          <div className="w-100">
                            <label
                              htmlFor={START_DATE_FIELD}
                              className="fs-4 fw-medium text-secondary-emphasis mb-1"
                            >
                              Lottery Start Date
                            </label>
                            <div
                              className={cn([
                                styles['datetime-input-group'],
                                'input-group',
                              ])}
                            >
                              <Field
                                type="date"
                                id={START_DATE_FIELD}
                                name={START_DATE_FIELD}
                                className="form-control"
                                aria-invalid={isInvalid(START_DATE_FIELD)}
                                aria-describedby="name-errors"
                                style={{ appearance: 'none' }}
                              />
                              <span className="input-group-text rounded-end">
                                <Calendar />
                              </span>
                              {isInvalid(START_DATE_FIELD) && (
                                <div
                                  id={`${START_DATE_FIELD}-errors`}
                                  className="text-danger mt-1 fs-4 w-100"
                                >
                                  {formik.errors[START_DATE_FIELD]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="w-100">
                            <label
                              htmlFor={START_TIME_FIELD}
                              className="fs-4 fw-medium text-secondary-emphasis mb-1"
                            >
                              Lottery Start Time
                            </label>
                            <div
                              className={cn([
                                styles['datetime-input-group'],
                                'input-group',
                              ])}
                            >
                              <Field
                                type="time"
                                id={START_TIME_FIELD}
                                name={START_TIME_FIELD}
                                className="form-control"
                                aria-invalid={isInvalid(START_TIME_FIELD)}
                                aria-describedby="name-errors"
                              />
                              <span className="input-group-text rounded-end">
                                <Clock />
                              </span>
                              {isInvalid(START_TIME_FIELD) && (
                                <div
                                  id={`${START_TIME_FIELD}-errors`}
                                  className="text-danger mt-1 fs-4 w-100"
                                >
                                  {formik.errors[START_TIME_FIELD]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="hr-divider d-lg-none" />
                        <div className="d-flex flex-column flex-lg-row gap-4 flex-fill w-100">
                          <div className="w-100">
                            <label
                              htmlFor={END_DATE_FIELD}
                              className="fs-4 fw-medium text-secondary-emphasis mb-1"
                            >
                              Lottery End Date
                            </label>
                            <div
                              className={cn([
                                styles['datetime-input-group'],
                                'input-group',
                              ])}
                            >
                              <Field
                                type="date"
                                id={END_DATE_FIELD}
                                name={END_DATE_FIELD}
                                className="form-control"
                                aria-invalid={isInvalid(END_DATE_FIELD)}
                                aria-describedby="name-errors"
                                style={{ appearance: 'none' }}
                              />
                              <span className="input-group-text rounded-end">
                                <Calendar />
                              </span>
                              {isInvalid(END_DATE_FIELD) && (
                                <div
                                  id={`${END_DATE_FIELD}-errors`}
                                  className="text-danger mt-1 fs-4 w-100"
                                >
                                  {formik.errors[END_DATE_FIELD]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="w-100">
                            <label
                              htmlFor={END_TIME_FIELD}
                              className="fs-4 fw-medium text-secondary-emphasis mb-1"
                            >
                              Lottery End Time
                            </label>
                            <div
                              className={cn([
                                styles['datetime-input-group'],
                                'input-group',
                              ])}
                            >
                              <Field
                                type="time"
                                id={END_TIME_FIELD}
                                name={END_TIME_FIELD}
                                className="form-control"
                                aria-invalid={isInvalid(END_TIME_FIELD)}
                                aria-describedby="name-errors"
                              />
                              <span className="input-group-text rounded-end">
                                <Clock />
                              </span>
                              {isInvalid(END_TIME_FIELD) && (
                                <div
                                  id={`${END_TIME_FIELD}-errors`}
                                  className="text-danger mt-1 fs-4 w-100"
                                >
                                  {formik.errors[END_TIME_FIELD]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </FieldRowRight>
                  </FieldRow>

                  <FieldRow fieldName={UNCLAIMED_TICKETS_FIELD}>
                    <FieldRowLeft>
                      <label
                        htmlFor={UNCLAIMED_TICKETS_FIELD}
                        className="fs-4 text-secondary-emphasis fw-semibold m-0"
                      >
                        Unclaimed Ticket Allocation*
                      </label>
                      <p className="fs-4 text-secondary m-0">
                        Select what you would like to do with any unclaimed
                        tickets after the lottery ends.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight>
                      <div className="w-100 d-flex flex-column gap-3 fs-4 text-gray">
                        {UNCLAIMED_TICKET_OPTIONS.map((unclaimedOption) => {
                          const isChecked =
                            formik.values[UNCLAIMED_TICKETS_FIELD] ===
                            unclaimedOption.value;

                          return (
                            <label
                              htmlFor={`${unclaimedOption.value}-option`}
                              id={`${unclaimedOption.value}-card`}
                              key={unclaimedOption.value}
                              className={cn([
                                'd-flex border border-gray-lighter p-4',
                                isChecked && styles['active-option-card'],
                                styles['option-card'],
                              ])}
                            >
                              <div
                                className="bg-primary-lighter d-flex align-items-center rounded-circle p-2"
                                style={{ width: 32, height: 32 }}
                              >
                                <unclaimedOption.Icon className="text-primary-darker" />
                              </div>
                              <div className="ps-4 pe-1">
                                <p
                                  className={cn([
                                    'text-gray-dark fw-medium mb-0',
                                    isChecked &&
                                      styles['active-option-card-header'],
                                  ])}
                                >
                                  {unclaimedOption.title}
                                </p>
                                <p className="mb-0">
                                  {unclaimedOption.description}
                                </p>
                                <Field
                                  type="radio"
                                  key={unclaimedOption.value}
                                  id={`${unclaimedOption.value}-option`}
                                  className="visually-hidden"
                                  checked={isChecked}
                                  value={unclaimedOption.value}
                                  onChange={formik.handleChange}
                                  name={UNCLAIMED_TICKETS_FIELD}
                                  aria-invalid={isInvalid(
                                    UNCLAIMED_TICKETS_FIELD,
                                  )}
                                  aria-describedby="name-errors"
                                  required
                                />
                              </div>
                              <div
                                className={cn([
                                  styles['icon-wrapper'],
                                  'border d-flex align-items-center rounded-circle',
                                  isChecked
                                    ? 'bg-primary border-primary'
                                    : 'bg-white border-gray-lighter',
                                ])}
                              >
                                {isChecked && (
                                  <Check className="text-white w-100" />
                                )}
                              </div>
                            </label>
                          );
                        })}
                        {isInvalid(UNCLAIMED_TICKETS_FIELD) && (
                          <div
                            id={`${UNCLAIMED_TICKETS_FIELD}-errors`}
                            className="text-danger mt-1 fs-4"
                          >
                            {formik.errors[UNCLAIMED_TICKETS_FIELD]}
                          </div>
                        )}
                      </div>
                    </FieldRowRight>
                  </FieldRow>

                  <FieldRow fieldName={EVENT_TYPE_FIELD}>
                    <FieldRowLeft>
                      <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
                        Event Type*
                      </p>
                      <p className="fs-4 text-secondary m-0">
                        Please choose the type of event your lottery
                        participants will be attending.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight>
                      <div className="w-100">
                        <label
                          htmlFor={EVENT_TYPE_FIELD}
                          className="fs-4 fw-medium text-secondary-emphasis mb-1"
                        >
                          Event Type
                        </label>
                        <Field
                          as="select"
                          id={EVENT_TYPE_FIELD}
                          name={EVENT_TYPE_FIELD}
                          className="form-select"
                          aria-invalid={isInvalid(EVENT_TYPE_FIELD)}
                          aria-describedby="name-errors"
                        >
                          <option disabled value="">
                            Select an event type
                          </option>

                          {
                            // TODO: This can come from the DB. There's already an endpoint for that
                            EVENT_TYPE_OPTIONS.map(({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))
                          }
                        </Field>
                        {isInvalid(EVENT_TYPE_FIELD) && (
                          <div
                            id={`${EVENT_TYPE_FIELD}-errors`}
                            className="text-danger mt-1 fs-4"
                          >
                            {formik.errors[EVENT_TYPE_FIELD]}
                          </div>
                        )}
                      </div>
                    </FieldRowRight>
                  </FieldRow>

                  <FieldRow fieldName={VENUE_NAME_FIELD}>
                    <FieldRowLeft>
                      <p
                        htmlFor={VENUE_NAME_FIELD}
                        className="fs-4 text-secondary-emphasis fw-semibold m-0"
                      >
                        Venue Details*
                      </p>
                      <p className="fs-4 text-secondary m-0">
                        This will be displayed on the lottery page to easily
                        give participants access to venue details.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight>
                      <div className="d-flex flex-column gap-4">
                        <div>
                          <label
                            htmlFor={VENUE_NAME_FIELD}
                            className="fs-4 fw-medium text-secondary-emphasis mb-1"
                          >
                            Venue Name
                          </label>
                          <Field
                            id={VENUE_NAME_FIELD}
                            name={VENUE_NAME_FIELD}
                            required
                            className="form-control"
                            placeholder="Provide the name of the venue"
                            aria-invalid={isInvalid(VENUE_NAME_FIELD)}
                            aria-describedby="name-errors"
                          />
                          {isInvalid(VENUE_NAME_FIELD) && (
                            <div
                              id={`${VENUE_NAME_FIELD}-errors`}
                              className="text-danger mt-1 fs-4"
                            >
                              {formik.errors[VENUE_NAME_FIELD]}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="input-group ">
                            <label
                              htmlFor={VENUE_LINK_FIELD}
                              className="input-group-text"
                            >
                              Venue URL
                            </label>
                            <Field
                              id={VENUE_LINK_FIELD}
                              name={VENUE_LINK_FIELD}
                              required
                              className="form-control"
                              placeholder="Provide the website of the venue"
                              aria-invalid={isInvalid(VENUE_LINK_FIELD)}
                              aria-describedby="name-errors"
                            />
                          </div>
                          {isInvalid(VENUE_LINK_FIELD) && (
                            <div
                              id={`${VENUE_LINK_FIELD}-errors`}
                              className="text-danger mt-1 fs-4"
                            >
                              {formik.errors[VENUE_LINK_FIELD]}
                            </div>
                          )}
                        </div>
                      </div>
                    </FieldRowRight>
                  </FieldRow>

                  <FieldRow fieldName={EVENT_LOGO_FIELD}>
                    <FieldRowLeft>
                      <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
                        Event Logo
                      </p>
                      <p className="fs-4 text-secondary m-0">
                        Add a logo for participants to easily recognize their
                        favorite sports team or event venue.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight>
                      <div className="d-flex flex-column flex-lg-row gap-8 align-items-start justify-content-start w-100">
                        <div
                          className={cn([
                            styles['event-image-wrapper'],
                            'd-flex align-items-center justify-content-center',
                          ])}
                        >
                          <Avatar
                            altText="LT"
                            altBackground="yellow"
                            size="md"
                            style={{
                              width: '4rem',
                              height: '4rem',
                              fontSize: '2rem',
                            }}
                            imgSrc={formik.values[EVENT_LOGO_FIELD] ?? null}
                          />
                        </div>

                        <div className="w-100">
                          <DragAndDrop
                            altText="Event logo upload field"
                            multiple={false}
                            onFileUpload={(event) =>
                              setImageFormField(event, EVENT_LOGO_FIELD)
                            }
                            accept={{
                              'image/*': ['.png', '.svg', '.jpg', '.gif'],
                            }}
                            maxSize={5 * 1024 * 1024}
                            uploadDescription="SVG, PNG, JPG or GIF (max. 800x800px)"
                            controlId="logo-upload"
                          />
                          {isInvalid(EVENT_LOGO_FIELD) && (
                            <div
                              id={`${EVENT_LOGO_FIELD}-errors`}
                              className="text-danger mt-1 fs-4"
                            >
                              {formik.errors[EVENT_LOGO_FIELD]}
                            </div>
                          )}
                        </div>
                      </div>
                    </FieldRowRight>
                  </FieldRow>

                  <FieldRow fieldName={EVENT_COVER_PHOTO_FIELD}>
                    <FieldRowLeft>
                      <p className="fs-4 text-secondary-emphasis fw-semibold m-0">
                        Event Cover Photo
                      </p>
                      <p className="fs-4 text-secondary m-0">
                        Add a captivating photo to enhance your lottery page and
                        get participants excited.
                      </p>
                    </FieldRowLeft>
                    <FieldRowRight>
                      <div className="d-flex flex-column flex-lg-row gap-4 gap-lg-8 align-items-start justify-content-start w-100">
                        <div
                          className={cn([
                            styles['event-image-wrapper'],
                            'd-flex align-items-center justify-content-center',
                          ])}
                        >
                          <HeroImage
                            imgSrc={
                              formik.values[EVENT_COVER_PHOTO_FIELD] ?? null
                            }
                            style={{ width: '7.25rem', height: '4rem' }}
                            className="rounded-2"
                          />
                        </div>

                        <div className="w-100">
                          <DragAndDrop
                            altText="Event cover photo upload field"
                            multiple={false}
                            onFileUpload={(event) =>
                              setImageFormField(event, EVENT_COVER_PHOTO_FIELD)
                            }
                            accept={{
                              'image/*': ['.png', '.svg', '.jpg', '.gif'],
                            }}
                            maxSize={5 * 1024 * 1024}
                            uploadDescription="SVG, PNG, JPG or GIF (max. 800x800px)"
                            controlId="cover-upload"
                          />
                          {isInvalid(EVENT_COVER_PHOTO_FIELD) && (
                            <div
                              id={`${EVENT_COVER_PHOTO_FIELD}-errors`}
                              className="text-danger mt-1 fs-4"
                            >
                              {formik.errors[EVENT_COVER_PHOTO_FIELD]}
                            </div>
                          )}
                        </div>
                      </div>
                    </FieldRowRight>
                  </FieldRow>
                </div>
              </Form>
              <div className="d-flex justify-content-lg-end w-100">
                <ActionButtons
                  actionLabel={actionLabel}
                  handleAction={() => formik.handleSubmit()}
                  handleCancel={handleCancel}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default LotteryDetails;
