import { CURRENT_ORG_STORE } from 'constants';
import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import cn from 'utils/classNames';
import UserContext, { userContextContract } from 'UserContext';
import { ToastMessagesContext } from 'hooks/useToastMessages';
import SidebarMenu from './SidebarMenu/SidebarMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import Footer from './Footer/Footer';
import Loading from './Loading/Loading';

import styles from './Default.module.scss';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { protectedResources } from '../../authConfig';

function LayoutContent({ children }) {
  const [userContext, setUserContext] = useState(null);
  const [toastMessagesContext, setToastMessagesContext] = useState([]);
  const [currentOrgId, setCurrentOrgId] = useState(null);
  const [expiringContext, setExpireContext] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);

  const handleExpiringContext = () => {
    setExpireContext(true);
  };

  const { execute } = useFetchWithMsal({
    scopes: protectedResources.usercontext.scopes.access_as_user,
  });

  /**
   * Dismisses a toast message identified by its timestamp.
   *
   * @param {number} dissmissedTimestamp Timestamp of the toast message that should be dismissed.
   */
  const handleDismissToast = (dissmissedTimestamp) => {
    const remainingToastMessages = toastMessages.filter(
      (errMsg) => errMsg.timestamp !== dissmissedTimestamp,
    );
    setToastMessages(remainingToastMessages);
  };

  useEffect(() => {
    setToastMessagesContext({
      /**
       * Adds a new toast message to the list.
       *
       * @param {{ title: string, message: string }} toastObject Object with title and message to create a new toast.
       */
      handleNewToast: ({ title, message }) => {
        setToastMessages(() => [
          ...toastMessages,
          {
            timestamp: Date.now(),
            title,
            message,
          },
        ]);
      },
      toastMessages,
    });
  }, [toastMessages, toastMessages.length]);

  useEffect(() => {
    if (
      expiringContext === true ||
      currentOrgId !== userContext?.organizationId
    ) {
      execute('GET', `${protectedResources.usercontext.endpoint}/current`).then(
        async (currentUserData) => {
          const companyPlanData = await execute(
            'GET',
            `${protectedResources.subscriptions.endpoint}/${currentUserData.organizationUserRoles[0].organizationId}`,
          );

          let {
            organizationUserRole,
            organizationUserId,
            organizationName,
            organizationId,
          } = currentUserData.organizationUserRoles[0];

          const storedOrgId = localStorage.getItem(CURRENT_ORG_STORE);

          // If the currentOrgId is not set yet, set it now
          if (!currentOrgId && !storedOrgId) {
            setCurrentOrgId(organizationId);
          } else {
            // if we have a currentOrgId we need to handle the org change
            const newOrg = currentUserData.organizationUserRoles.find(
              (org) =>
                org.organizationId === storedOrgId ||
                org.organizationId === currentOrgId,
            );

            if (newOrg) {
              organizationUserRole = newOrg.organizationUserRole;
              organizationUserId = newOrg.organizationUserId;
              organizationName = newOrg.organizationName;
              organizationId = newOrg.organizationId;
            } else {
              // there was no matching org, destroy local storage
              localStorage.removeItem(CURRENT_ORG_STORE);
            }
          }

          setUserContext(
            userContextContract({
              ...currentUserData,
              organizationId,
              organizationUserRole,
              organizationUserId,
              organizationName,
              companyPlan: companyPlanData,
              expireContext: handleExpiringContext,
              changeOrganization: setCurrentOrgId,
            }),
          );
          setExpireContext(false);
        },
      );
    }
  }, [currentOrgId, execute, expiringContext, userContext?.organizationId]);

  useEffect(() => {
    setToastMessagesContext({
      /**
       * Adds a new toast message to the list.
       *
       * @param {{ title: string, message: string }} toastObject Object with title and message to create a new toast.
       */
      handleNewToast: ({ title, message }) => {
        setToastMessages(() => [
          ...toastMessages,
          {
            timestamp: Date.now(),
            title,
            message,
          },
        ]);
      },
      toastMessages,
    });
  }, [toastMessages, toastMessages.length]);

  return userContext ? (
    <UserContext.Provider value={userContext}>
      <ToastMessagesContext.Provider value={toastMessagesContext}>
        <MobileMenu />

        <div className={styles['app-wrapper']}>
          <SidebarMenu
            className={cn([styles['sidebar-menu'], 'd-lg-flex d-none'])}
          />

          <div className={cn([styles['app-content'], 'py-0 pe-0'])}>
            <main>
              {children}
              <ToastContainer
                className="p-3 position-fixed"
                position="bottom-end"
                style={{ zIndex: 1 }}
              >
                {!toastMessages?.length ||
                  toastMessages.map((mssg) => (
                    <Toast
                      key={mssg.timestamp}
                      onClose={() => handleDismissToast(mssg.timestamp)}
                      delay={12000}
                      autohide
                    >
                      <Toast.Header className="text-primary">
                        <strong className="me-auto">{mssg.title}</strong>
                      </Toast.Header>
                      <Toast.Body className="bg-white">
                        {mssg.message}
                      </Toast.Body>
                    </Toast>
                  ))}
              </ToastContainer>
            </main>
            <Footer />
          </div>
        </div>
      </ToastMessagesContext.Provider>
    </UserContext.Provider>
  ) : (
    <Loading />
  );
}

export default function Default({ children }) {
  return <LayoutContent>{children}</LayoutContent>;
}
