const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID || '';

const NO_SUBSCRIPTION_PLAN_NAME = 'No subscription';
const NO_SUBSCRIPTION_REDIRECT_PATH = '/admin/account-settings/plan-billing';

const USE_CONSOLE_LOGGING =
  process.env.REACT_APP_ENABLE_USE_CONSOLE_LOGGING?.toLowerCase() === 'true' ||
  false;

const APPLICATION_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY || '';

// TODO: Add purple. It has been removed until a purple ticket asset has been provided.
const DEFAULT_BACKGROUND_COLORS = ['yellow', 'green', 'blue', 'pink'];

const ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN';
const LOTTERY_PARTICIPANT = 'LOTTERY_PARTICIPANT';
/**
 * Notification method bytes. These are used to determine which notification methods
 * are enabled for a user. The bytes are used to set bits in a byte array.
 * For example, if a user has email and text notifications enabled, the byte array
 * would be [1, 0, 1, 0, 0, 0, 0, 0] (email is 1, text is 4).
 * The byte array is then converted to a decimal number, which is stored in the database.
 * When the user logs in, the decimal number is converted to a byte array, which is then
 * used to determine which notification methods are enabled.
 * @type {{EMAIL: number, INAPP: number, TEXT: number}}
 */
const NOTIFICATION_METHOD_BYTES = {
  EMAIL: 1,
  INAPP: 2,
  TEXT: 4,
};

// eslint-disable-next-line import/prefer-default-export
export {
  ORGANIZATION_ADMIN,
  LOTTERY_PARTICIPANT,
  ADB2C_CLIENT_ID,
  USE_CONSOLE_LOGGING,
  DEFAULT_BACKGROUND_COLORS,
  NOTIFICATION_METHOD_BYTES,
  NO_SUBSCRIPTION_PLAN_NAME,
  NO_SUBSCRIPTION_REDIRECT_PATH,
  APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
};

// lottery status
export const UPCOMING = 'Upcoming';
export const ACTIVE = 'Active';
export const COMPLETE = 'Complete';
export const DRAFT = 'Draft';
export const PASSED = 'Passed';

// Local storage key for currently set organization
export const CURRENT_ORG_STORE = 'current-org-store';
