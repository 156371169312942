import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import TagManager from 'react-gtm-module';
import { ErrorBoundary } from 'react-error-boundary';
import { msalConfig, b2cPolicies, redirectToLoginErrorCodes } from 'authConfig';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';
import { compareIssuingPolicy } from 'utils/claimUtils';
import App from './App';
import AuthenticationProvider from './AuthenticationProvider';
import ErrorComponent from './layouts/Default/ErrorComponent/ErrorComponent';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
// eslint-disable-next-line import/prefer-default-export
export const msalInstance = new PublicClientApplication(msalConfig);

if (process.env.REACT_APP_GTM_CONTAINER_ID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_CONTAINER_ID });
}

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
      event.payload.account
    ) {
      /**
       * Below we are checking if the user is returning from the reset password flow.
       * If so, we will ask the user to reauthenticate with their new password.
       * If you do not want this behavior and prefer your users to stay signed in instead,
       * you can replace the code below with the same pattern used for handling the return from
       * profile edit flow
       */
      if (
        compareIssuingPolicy(
          event.payload.idTokenClaims,
          b2cPolicies.names.forgotPassword,
        )
      ) {
        const signUpSignInFlowRequest = {
          authority: b2cPolicies.authorities.signUpSignIn.authority,
        };
        msalInstance.loginRedirect(signUpSignInFlowRequest);
      }
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (
        event.error &&
        event.error.errorMessage.includes(
          b2cPolicies.authorities.forgotPassword.errorCode,
        )
      ) {
        const resetPasswordRequest = {
          authority: b2cPolicies.authorities.forgotPassword.authority,
          scopes: [],
        };
        msalInstance.loginRedirect(resetPasswordRequest);
      }

      // The user cancelling policies such as forgot password, or linkedin login
      // results in an error with particular error code. Redirect back to the signin
      // page for particular error codes.
      if (
        event.error &&
        redirectToLoginErrorCodes.some((errorCode) =>
          event.error.errorMessage.includes(errorCode),
        )
      ) {
        const signUpSignInFlowRequest = {
          authority: b2cPolicies.authorities.signUpSignIn.authority,
        };
        msalInstance.loginRedirect(signUpSignInFlowRequest);
      }
    }

    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
    ) {
      msalInstance.setActiveAccount(event.payload.account);
    }
  });

  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    // TODO: React strict mode is currently disabled until react team or msal team address the issue: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5468
    // <React.StrictMode>

    <BrowserRouter>
      <AuthenticationProvider pca={msalInstance}>
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <App />
        </ErrorBoundary>
      </AuthenticationProvider>
    </BrowserRouter>,
    // </React.StrictMode>,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
