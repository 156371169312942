import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import ActionButtons from 'components/ActionButtons';
import './LotterySchedule.scss';
import { TableHeader } from 'components/Table';
import Ticket from 'assets/icons/ticket.svg';
import Trash from 'assets/icons/trash.svg';
import Plus from 'assets/icons/plus.svg';
import { SCHEDULE_FORM_TYPE, SCHEDULE_STEP } from 'constants/addLottery';

// Set licencse for AG Grid Enterprise
if (process.env.REACT_APP_AG_GRID_LICENSE) {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);
}

const EVENT_ID = 'event-id';
const EVENT_NAME = 'name';
const EVENT_DATE = 'eventDate';
const TIME = 'time';
const ROW = 'row';
const SECTION = 'section';
const SEAT = 'seat';
const ADDITIONAL_DETAILS = 'additionalDetails';

const eventIdSchema = Yup.string().uuid();

const eventNameSchema = Yup.string()
  .max(64, 'Event Name: Please enter no more than 64 characters.')
  .required('Event Name is required');

const eventDateSchema = Yup.string()
  .test({
    name: 'timeFormat',
    test: (value) => {
      if (!value) return true;
      const dateObj = new Date(value);
      if (Number.isNaN(dateObj.getTime())) {
        return false;
      }
      const dateString = dateObj.toISOString();
      // validates strings representing a date and time in the ISO 8601 format, including the UTC time zone.
      const regex =
        /^(?:\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-9]|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,3})?Z)$/;
      return regex.test(dateString);
    },
    message: 'Event Date: please enter a valid date.',
  })
  .required('Event Date is required');

const timeSchema = Yup.string()
  .test({
    name: 'timeFormat',
    message: 'Time is not valid.',
    test: (value) => {
      if (!value) return true;
      // Use a regular expression to validate the time format "h:mm A"
      const regex = /^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/i;
      return regex.test(value);
    },
  })
  .required('Time is required');

const rowSchema = Yup.string();

const seatSchema = Yup.string()
  .max(16, 'Seat: Please enter no more than 16 characters.')
  .required('Seat is required');

const sectionSchema = Yup.string().max(
  16,
  'Section: Please enter no more than 16 characters.',
);

const additionalDetailsSchema = Yup.string().max(
  255,
  'Additional Details: Please enter no more than 255 characters',
);

const validationSchema = Yup.object().shape({
  eventSchedule: Yup.array()
    .of(
      Yup.object().shape({
        [EVENT_ID]: eventIdSchema,
        [EVENT_NAME]: eventNameSchema,
        [EVENT_DATE]: eventDateSchema,
        [TIME]: timeSchema,
        [ROW]: rowSchema,
        [SEAT]: seatSchema,
        [SECTION]: sectionSchema,
        [ADDITIONAL_DETAILS]: additionalDetailsSchema,
      }),
    )
    .test({
      message: 'You must provide at least one valid ticket row to continue.',
      test: (arr) => arr.length > 0,
    }),
});

// eslint-disable-next-line react/display-name
const CellRenderer = forwardRef((props, ref) => {
  const {
    value: originalValue,
    formik,
    rowIndex,
    colDef: { field },
    placeholderText,
  } = props;
  const [value, setValue] = useState(originalValue);

  useImperativeHandle(ref, () => ({
    refresh(params) {
      setValue(params.value);
      return true;
    },
  }));

  // If we have errors, return immediately
  if (formik.errors.eventSchedule && formik.touched.eventSchedule) {
    if (
      formik.errors.eventSchedule[rowIndex] &&
      formik.touched.eventSchedule[rowIndex]
    ) {
      if (
        formik.errors.eventSchedule[rowIndex][field] &&
        formik.touched.eventSchedule[rowIndex][field]
      )
        return (
          <span className="fw-semibold text-danger">
            {formik.errors.eventSchedule[rowIndex][field]}
          </span>
        );
    }
  }

  // If we have no values yet, return placeholder
  if (placeholderText && !value) {
    return (
      <span className="text-info fst-italic text-opacity-75">
        {placeholderText}
      </span>
    );
  }

  if (Object.prototype.toString.call(value) === '[object Date]') {
    // Output string "Wednesday, April 4, 2024"
    const displayDate = format(value, 'EEEE, MMMM d, yyyy');

    // Build the custom format date string 'm/d/YYYY'
    return <span>{`${displayDate}`}</span>;
  }

  return <span>{value}</span>;
});

const createRowId = () => window?.crypto?.randomUUID() || '';

const handleRowId = (params) => params.data[EVENT_ID];

const generateFirstRow = () => ({
  eventSchedule: [
    {
      [EVENT_ID]: createRowId(),
      [EVENT_NAME]: '',
      [EVENT_DATE]: null,
      [TIME]: '',
      [ROW]: '',
      [SECTION]: '',
      [SEAT]: '',
      [ADDITIONAL_DETAILS]: '',
    },
  ],
});

/**
 *
 * @param {Object} props
 * @param {Function} props.handleLotterySchedule handler for lottery schedule parent state
 * @param {Object} props.unpublishedLottery Drafted lottery, if one exists
 * @param {boolean} props.sketchyReset Used to force the AgGrid values to clear when the lottery is successfully published
 * @returns
 */
function LotterySchedule({ handleLotterySchedule, unpublishedLottery }) {
  const agGridRef = useRef();
  const navigate = useNavigate();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const handleDismissErrorDialog = () => setShowErrorDialog(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [initialValues, setInitialValues] = useState(generateFirstRow());

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema,
    onSubmit: async (values, { setValues, setSubmitting }) => {
      const formattedValues = values.eventSchedule.map((row) => {
        let date = '';
        if (
          Object.prototype.toString.call(row[EVENT_DATE]) === '[object Date]'
        ) {
          // Get the month and pad it with a leading zero if it is less than 10
          const month = (row[EVENT_DATE].getMonth() + 1)
            .toString()
            .padStart(2, '0');
          // Get the day and pad it with a leading zero if it is less than 10
          const day = row[EVENT_DATE].getDate().toString().padStart(2, '0');

          const year = row[EVENT_DATE].getFullYear();

          // format date
          date = `${year}-${month}-${day}`;
        }
        return {
          ...row,
          [EVENT_DATE]: date,
        };
      });
      handleLotterySchedule({ eventSchedule: formattedValues });
      setSubmitting(false);
      setValues(generateFirstRow());
      return true;
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const unpublishedEventSchedule =
      unpublishedLottery[SCHEDULE_STEP]?.eventSchedule;
    if (unpublishedEventSchedule) {
      setInitialValues({
        eventSchedule: unpublishedEventSchedule.map((scheduledEvent) => {
          const [year, month, day] = scheduledEvent[EVENT_DATE].split('-');
          return {
            ...scheduledEvent,
            [EVENT_DATE]: new Date(year, month - 1, day),
          };
        }),
      });
    }
  }, [unpublishedLottery]);

  const handleDataFromClipboard = (params) => {
    const data = [...params.data];

    const emptyLastRow =
      data[data.length - 1][0] === '' && data[data.length - 1].length === 1;
    if (emptyLastRow) {
      data.splice(data.length - 1, 1);
    }

    const lastIndex = params.api.getModel().getRowCount() - 1;
    const focusedCell = params.api.getFocusedCell();
    const focusedIndex = focusedCell.rowIndex;
    let newRowIndex = focusedIndex || 0;

    if (focusedIndex + data.length - 1 > lastIndex) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < data.length; i++) {
        const row = data.slice(i, i + 1)[0];

        // set formik state
        formik.setFieldValue(
          `eventSchedule[${newRowIndex}].${EVENT_ID}`,
          createRowId(),
          true,
        );

        // Create row object
        let currentColumn = focusedCell.column;
        // eslint-disable-next-line no-loop-func
        row.forEach((value) => {
          if (!currentColumn) {
            return;
          }

          let newValue = value;

          if (currentColumn.colDef.field === EVENT_DATE) {
            const dateObj = new Date(value);
            // Ensure the date is valid
            if (!Number.isNaN(dateObj.getTime())) {
              newValue = dateObj;
            }
          }

          if (newValue === null) {
            newValue = '';
          }

          // set formik state
          formik.setFieldValue(
            `eventSchedule[${newRowIndex}].${currentColumn.colDef.field}`,
            newValue,
            true,
          );

          currentColumn = params.api.getDisplayedColAfter(currentColumn);
        });

        newRowIndex += 1;
      }

      // params.api.applyTransaction({ add: rowsToAdd });
      setTimeout(() => {
        formik.validateForm();
      }, 500);
    }

    return data;
  };

  const handleCellFromClipboard = (params) => {
    agGridRef.current.api.stopEditing();
    let newValue = params.value;
    if (params.column.colDef.field === EVENT_DATE) {
      const [year, month, day] = newValue.split('-');
      const dateObj = new Date(year, month - 1, day);
      // Ensure the date is valid
      if (!Number.isNaN(dateObj.getTime())) {
        newValue = dateObj;
      }
    }
    return newValue;
  };

  const handleNewRow = () => {
    formik.setValues(
      {
        eventSchedule: [
          ...formik.values.eventSchedule,
          {
            [EVENT_ID]: createRowId(),
            [EVENT_NAME]: '',
            [EVENT_DATE]: null,
            [TIME]: '',
            [ROW]: '',
            [SECTION]: '',
            [SEAT]: '',
            [ADDITIONAL_DETAILS]: '',
          },
        ],
      },
      false,
    );
  };

  const handleRemoveRow = () => {
    const removableIds = selectedRows?.map(
      (selectedRow) => selectedRow[EVENT_ID],
    );
    const purgedRows = formik.values.eventSchedule.filter(
      (event) => !removableIds.includes(event[EVENT_ID]),
    );
    formik.setValues(
      {
        eventSchedule: [...purgedRows],
      },
      false,
    );
  };

  const handleSelectionChanged = ({ api }) => {
    const rows = api.getSelectedRows();
    setSelectedRows([...rows]);
  };

  const handleSubmit = () => {
    agGridRef.current.api.stopEditing();
    formik.handleSubmit();
  };

  /**
   * Handle the imparative setting of the schedule to its original state
   * This is needed due to limitations with AgGrid, Fromick, and array values.
   */
  useEffect(() => {
    formik.setValues(generateFirstRow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only show the dialog if the form is invalid, is being submitted but is NOT currently running validation
    if (!formik.isValid && formik.isSubmitting && !formik.isValidating) {
      setShowErrorDialog(true);
    }
  }, [formik.isValid, formik.isValidating, formik.isSubmitting]);

  return (
    <div
      className="d-flex flex-column align-items-start justify-content-start mt-8 mb-12"
      data-form_type={SCHEDULE_FORM_TYPE}
    >
      <section className="d-flex flex-column align-items-start gap-5 w-100">
        <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between gap-4 w-100">
          <div className="d-flex flex-column align-items-start gap-1">
            <h2 className="m-0 fs-2 fw-semibold lh-lg">
              Step 2: Add Event Schedule and Tickets
            </h2>
            <p className="m-0 fs-4 text-secondary">
              Organize and share your event schedule and add your ticket details
              here.
            </p>
          </div>
          <ActionButtons
            actionLabel="Next: Set Lottery Rules"
            handleAction={handleSubmit}
            handleCancel={() => navigate('/season-lotteries')}
            isLoading={formik.isSubmitting}
          />
        </div>
        <div className="hr-divider" />
      </section>
      <div className="w-100 d-flex flex-column gap-5">
        <div>
          <div className="rounded-top-4 d-flex overflow-hidden flex-column w-auto shadow-sm border border-gray-lighter  mt-4">
            <TableHeader
              title="Add or Edit Event Schedule and Tickets"
              description="Click into a cell to edit event details. You can also copy and paste your data using the keyboard."
              totalItems={
                formik.values.eventSchedule &&
                formik.values.eventSchedule.length
              }
            >
              <div className="d-flex align-items-center gap-2">
                {selectedRows.length > 0 && (
                  <Button variant="tertiary" onClick={() => handleRemoveRow()}>
                    <Trash />
                    Remove Row(s)
                  </Button>
                )}
                <Button variant="secondary" onClick={() => handleNewRow()}>
                  <Plus />
                  Add Event & Ticket Row
                </Button>
              </div>
            </TableHeader>
            <div
              style={{ height: '556px', width: '100%' }}
              className="ag-theme-quartz"
            >
              <AgGridReact
                ref={agGridRef}
                getRowId={handleRowId}
                rowData={formik.values.eventSchedule}
                columnDefs={[
                  {
                    field: EVENT_NAME,
                    headerName: 'Event Name',
                    cellRenderer: CellRenderer,
                    cellRendererParams: {
                      formik,
                      placeholderText: "Your event's name",
                    },
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                  },
                  {
                    field: EVENT_DATE,
                    headerName: 'Event Date',
                    cellDataType: 'date',
                    cellRenderer: CellRenderer,
                    cellRendererParams: {
                      formik,
                      placeholderText: new Date().toLocaleDateString(),
                    },
                  },
                  {
                    field: TIME,
                    headerName: 'Time',
                    cellRenderer: CellRenderer,
                    cellRendererParams: {
                      formik,
                      placeholderText: '12:00 PM',
                    },
                  },
                  {
                    field: ROW,
                    headerName: 'Section',
                    cellRenderer: CellRenderer,
                    cellRendererParams: {
                      formik,
                      placeholderText: 'C',
                    },
                  },
                  {
                    field: SECTION,
                    headerName: 'Row',
                    cellRenderer: CellRenderer,
                    cellRendererParams: {
                      formik,
                      placeholderText: '52',
                    },
                  },
                  {
                    field: SEAT,
                    headerName: 'Seat',
                    cellRenderer: CellRenderer,
                    cellRendererParams: {
                      formik,
                      placeholderText: 'E',
                    },
                  },
                  {
                    field: ADDITIONAL_DETAILS,
                    headerName: 'Additional Details',
                    cellRenderer: CellRenderer,
                    cellRendererParams: {
                      formik,
                      placeholderText: "Anything you'd like to add?",
                    },
                  },
                ]}
                defaultColDef={{
                  flex: 1,
                  minWidth: 180,
                  editable: true,
                  suppressMovable: true,
                  filter: 'agTextColumnFilter',
                  menuTabs: ['filterMenuTab'],
                  resizable: false,
                }}
                suppressAggFuncInHeader
                onCellEditingStopped={(cellEvent) => {
                  const { colDef, value, rowIndex } = cellEvent;
                  formik.setFieldValue(
                    `eventSchedule[${rowIndex}].${colDef.field}`,
                    value || '',
                    true,
                  );
                  formik.setFieldTouched(
                    `eventSchedule[${rowIndex}].${colDef.field}`,
                    true,
                    false,
                  );
                }}
                rowSelection="multiple"
                suppressRowClickSelection
                onSelectionChanged={({ api }) => {
                  handleSelectionChanged({ api });
                }}
                processDataFromClipboard={handleDataFromClipboard}
                processCellFromClipboard={handleCellFromClipboard}
              />
            </div>
          </div>
          <Modal
            show={showErrorDialog}
            onHide={handleDismissErrorDialog}
            backdrop
            keyboard
            centered
          >
            <Modal.Header className="align-items-start pb-5" closeButton>
              <div className="d-flex flex-column align-items-start">
                <div className="bg-primary-lighter p-3 rounded-circle mb-4">
                  <Ticket className="text-primary-darker" />
                </div>
                <h2 className="fs-2 fw-semibold w-100">Oh No!</h2>
                <p className="text-gray mb-0 fs-4">
                  Looks like there are some issues with the data your provided.
                  Please resolve them and try again.
                </p>
              </div>
            </Modal.Header>
            {formik.errors.eventSchedule && (
              <Modal.Body className="py-0 pb-2 text-gray mb-0 fs-4">
                <ul className="list-group">
                  {Array.isArray(formik.errors.eventSchedule) &&
                    formik.errors.eventSchedule.map((rowErrors, i) => {
                      if (!rowErrors) {
                        // eslint-disable-next-line react/no-array-index-key
                        return <div key={i + 1} />;
                      }
                      let errors = rowErrors;
                      // Check if the errors are objects before mapping the values
                      if (typeof rowErrors === 'object') {
                        errors = Object.entries(rowErrors)
                          .map(([, v]) => v)
                          .join(', ');
                      }
                      return (
                        <li
                          // eslint-disable-next-line react/no-array-index-key
                          key={i + 1}
                          className="list-group-item text-danger"
                        >
                          Row {i + 1} has the following errors: {errors}
                        </li>
                      );
                    })}
                  {!Array.isArray(formik.errors.eventSchedule) && (
                    <li
                      // eslint-disable-next-line react/no-array-index-key
                      key={1}
                      className="list-group-item text-danger"
                    >
                      {formik.errors.eventSchedule}
                    </li>
                  )}
                </ul>
              </Modal.Body>
            )}
            <Modal.Footer className="m-0">
              <Button
                variant="primary"
                size="lg"
                className="w-100 justify-content-center"
                onClick={handleDismissErrorDialog}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <section className="d-flex flex-column gap-5 w-100 mt-4">
            <div className="hr-divider" />
            <div className="d-flex justify-content-end">
              <ActionButtons
                actionLabel="Next: Set Lottery Rules"
                handleAction={handleSubmit}
                handleCancel={() => navigate('/season-lotteries')}
                isLoading={formik.isSubmitting}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default LotterySchedule;
