import React from 'react';
import Button from 'react-bootstrap/Button';
import ConsoleLogger from '../../utils/logger';

const logger = ConsoleLogger.getInstance();

/**
 * Wrapper for a ALLS table footer pagination element
 * @param {Object} props
 * @param {Number} props.currentPage
 * @param {Number} props.pageTotal
 * @param {Boolean} props.canNext
 * @param {Boolean} props.canPrevious
 * @param {() => void} props.handleNext
 * @param {() => void} props.handlePrevious
 * @returns {React.ReactElement}
 */
export default function TableFooter({
  currentPage,
  pageTotal,
  setCurrentPage,
}) {
  if (currentPage === 0) {
    logger.error('currentPage cannot be 0');
    return null;
  }

  if (!pageTotal || pageTotal < 1) {
    logger.error('pageTotal must be a number greater than 0');
    return null;
  }

  const canNext = currentPage !== pageTotal;
  const canPrevious = currentPage !== 1;
  return (
    <div
      id="TableOuterFooter"
      className="pt-3 pb-4 px-4 px-lg-6 d-flex gap-4 w-100 justify-content-between align-items-center"
    >
      <div className="fs-4 text-gray-dark">
        Page {currentPage} of {pageTotal}
      </div>
      <div className="d-flex">
        {/* TODO: handle can next, can previous state and disable if either connot act. Drill prop state handlers */}
        <Button
          variant="tertiary"
          size="sm"
          disabled={!canPrevious || pageTotal === 1}
          className="me-3"
          onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
        >
          Previous
        </Button>
        <Button
          variant="tertiary"
          disabled={!canNext || pageTotal === 1}
          size="sm"
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
